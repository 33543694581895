export const SIGN_UP_PROCESS_STEP = {
  COLLECT_BASIC_INFO: "COLLECT_BASIC_INFO",
  VERIFY_PHONE_NUMBER: "VERIFY_PHONE_NUMBER",
  CONNECT_BANK: "CONNECT_BANK",
  RETRIEVE_BANK_DATA: "RETRIEVE_BANK_DATA",
  CONNECT_PROPERTY: "CONNECT_PROPERTY",
  SET_BUYING_GOAL: "SET_BUYING_GOAL",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
};

export const SIGN_UP_COLLECT_BASIC_INFO_STEP = {
  FULL_NAME: 0,
  PHONE_NUMBER: 1,
  EMAIL: 2,
  PASSWORD: 3,
  DONE: 4,
};
