import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import React, { useContext, useEffect, useReducer } from "react";
import { checkIfInsitutionAccountDataIsReadyToBeRetrieved } from "../../../api/finance-views";
import { INSTITUTION_ACCOUNT_ADDITIONAL_STATUS } from "../../../commons/enum";
import { SIGN_UP_PROCESS_STEP } from "../../../commons/signup.constants";
import SignupContext from "../../../context/sign-up-context";
import { useQueryInvalidationWithNotification } from "../../../hooks";
import { useUpdateUserEmployment } from "../../../hooks/scenario.hooks";

const DATA_READINESS_STATES = {
  NOT_FETCHED: "NOT_FETCHED",
  FETCHING: "FETCHING",
  READY: "READY",
  ERROR: "ERROR",
};

const INITIAL_STATE = {
  accountDataReadiness: DATA_READINESS_STATES.NOT_FETCHED,
  transactionDataReadiness: DATA_READINESS_STATES.NOT_FETCHED,
};

const ACTIONS = {
  START_CHECKING: "START_CHECKING",
  ACCOUNT_DATA_READY: "ACCOUNT_DATA_READY",
  ALL_DATA_READY: "ALL_DATA_READY",
  DATA_ERROR: "DATA_ERROR",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.START_CHECKING:
      return {
        ...state,
        accountDataReadiness: DATA_READINESS_STATES.FETCHING,
        transactionDataReadiness: DATA_READINESS_STATES.FETCHING,
      };
    case ACTIONS.ACCOUNT_DATA_READY:
      return {
        ...state,
        accountDataReadiness: DATA_READINESS_STATES.READY,
      };
    case ACTIONS.ALL_DATA_READY:
      return {
        ...state,
        accountDataReadiness: DATA_READINESS_STATES.READY,
        transactionDataReadiness: DATA_READINESS_STATES.READY,
      };
    case ACTIONS.DATA_ERROR:
      return {
        ...state,
        accountDataReadiness: DATA_READINESS_STATES.ERROR,
        transactionDataReadiness: DATA_READINESS_STATES.ERROR,
      };
    default:
      return state;
  }
}

const SignUpRetrieveBankData = () => {
  const { setSignupProcess, connectedInstitutionAccount } = useContext(
    SignupContext
  );
  const invalidateQueries = useQueryInvalidationWithNotification();
  const updateUserEmployment = useUpdateUserEmployment();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { accountDataReadiness, transactionDataReadiness } = state;

  const handleNext = () => {
    setSignupProcess(SIGN_UP_PROCESS_STEP.CONNECT_PROPERTY);
  };

  const handleTryAgain = () => {
    setSignupProcess(SIGN_UP_PROCESS_STEP.CONNECT_BANK);
  };

  const refreshFinanceData = async () => {
    await invalidateQueries("accountList");
    await invalidateQueries("financeHistory");
    return updateUserEmployment.mutate();
  };

  const callOnAllDataReady = () => {
    dispatch({ type: ACTIONS.ALL_DATA_READY });
    refreshFinanceData();
  };

  const isLoading =
    accountDataReadiness === DATA_READINESS_STATES.FETCHING ||
    transactionDataReadiness === DATA_READINESS_STATES.FETCHING;

  const isError =
    accountDataReadiness === DATA_READINESS_STATES.ERROR &&
    transactionDataReadiness === DATA_READINESS_STATES.ERROR;

  const isSuccessful =
    accountDataReadiness === DATA_READINESS_STATES.READY &&
    transactionDataReadiness === DATA_READINESS_STATES.READY;

  useEffect(() => {
    const callback = (additionalStatus) => {
      switch (additionalStatus) {
        case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.ACCT_SUMMARY_RECEIVED:
          dispatch({ type: ACTIONS.ACCOUNT_DATA_READY });
          break;
        case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.PARTIAL_DATA_RETRIEVED:
        case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.AVAILABLE_DATA_RETRIEVED:
          callOnAllDataReady();
          break;
        default:
      }
    };
    if (connectedInstitutionAccount) {
      dispatch({ type: ACTIONS.START_CHECKING });
      checkIfInsitutionAccountDataIsReadyToBeRetrieved({
        ...connectedInstitutionAccount,
        callback,
      })
        .then((ready) => {
          if (ready) {
            callOnAllDataReady();
          } else {
            dispatch({ type: ACTIONS.DATA_ERROR });
          }
        })
        .catch(() => {
          dispatch({ type: ACTIONS.DATA_ERROR });
        });
    } else {
      dispatch({ type: ACTIONS.DATA_ERROR });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTitle = () => {
    if (isLoading) {
      return "Connecting Data";
    }
    if (isError) {
      return "Failed";
    }
    return "Success";
  };

  const renderIndicator = (dataState) => {
    switch (dataState) {
      case DATA_READINESS_STATES.FETCHING:
        return <CircularProgress size={20} />;
      case DATA_READINESS_STATES.ERROR:
        return <ClearIcon style={{ color: "#fd4c4c" }} />;
      case DATA_READINESS_STATES.READY:
        return <DoneIcon />;
      default:
        return "";
    }
  };

  return (
    <div className="sign-up-retrieve-bank-data body-context-container">
      <div className="signup-title">{renderTitle()}</div>
      <div className="sign-up-retrieve-bank-data__info">
        <div className="sign-up-retrieve-bank-data__info-row">
          <div>Getting Account Data</div>
          <div className="sign-up-retrieve-bank-data__info-icon">
            {renderIndicator(accountDataReadiness)}
          </div>
        </div>
        <div className="sign-up-retrieve-bank-data__info-row">
          <div className="">Getting Transaction Data</div>
          <div className="sign-up-retrieve-bank-data__info-icon">
            {renderIndicator(transactionDataReadiness)}
          </div>
        </div>
      </div>
      {isSuccessful && (
        <div
          className="custom-button-fill sign-up-retrieve-bank-data__button"
          onClick={handleNext}
          role="button"
          tabIndex="0"
        >
          Next
        </div>
      )}
      {isError && (
        <div
          className="custom-button-fill sign-up-retrieve-bank-data__button"
          onClick={handleTryAgain}
          role="button"
          tabIndex="0"
        >
          Try again
        </div>
      )}
    </div>
  );
};

export default SignUpRetrieveBankData;
